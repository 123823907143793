<template>
  <div class="flex-rows w-full bg-white p-2 rounded-lg my-2 pb-6">
    <div class="flex w-full justify-end items-center">
      <div class="cursor-pointer" @click="handleCancel">
        <!-- X-mark -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          class="w-4 h-4"
        >
          <path
            d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
          />
        </svg>
      </div>
    </div>
    <FormulateForm @submit="handleSubmit()">
      <div class="flex w-full justify-between items-start gap-5">
        <!-- status name -->
        <div class="w-4/12">
          <FormulateInput
            type="text"
            v-model="form.riskLabelEntity"
            label="Label"
            placeholder="Label Name"
            validation="bail|required|max:30,length|matches:/^[a-zA-Z0-9 ]*[a-zA-Z0-9]+[a-zA-Z0-9 ]*$/"
            :validation-messages="{
              matches: 'Only alphanumeric characters and spaces are allowed',
            }"
            :element-class="
              (context, classes) => ['flex-1 min-w-full'].concat(classes)
            "
            error-behavior="value"
            @validation="validationName"
            @input="handleNameInput"
          >
          </FormulateInput>
        </div>
        <!-- risk color -->
        <div class="w-3/12">
          <risk-color
            :color-options="colorList"
            label="Color"
            :required="true"
            placeholder="#000"
            @input="handleSelectColor"
            :statusDetail="status"
          />
        </div>
        <!-- Description -->
        <div class="w-4/12">
          <FormulateInput
            type="text"
            v-model="form.riskDescription"
            label="Description"
            placeholder="Description"
            :validation="form.riskDescription ? 'bail|max:10000000,length|matches:/^[a-zA-Z0-9 ]*[a-zA-Z0-9]+[a-zA-Z0-9 ]*$/' : '' "
            :validation-messages="{
              matches: 'Only alphanumeric characters and spaces are allowed',
            }"
            :element-class="
              (context, classes) => [`flex-1 min-w-full`].concat(classes)
            "
            error-behavior="value"
            @input="handleDescriptionInput"
            @validation="validationDescription"
          >
          </FormulateInput>
        </div>
        <!-- Score Range  -->
        <div class="w-4/12">
          <div class="formulate-input" data-classification="text" data-type="text">
            <label for="minInput" class="label items-center capitalize " style="justify-content: normal;">Risk Score Range <span class="text-red-600 px-1">*</span>
            </label>
            <div class="flex-1 flex min-w-full formulate-input-element formulate-input-element--text">
              <div class="pr-2">
                <input
                  class="mr-2"
                  id="minInput"
                  type="text"
                  v-model="form.minValue"
                  placeholder="Min"
                  @keyup="validateMinMaxInput"
                  :class="{ 'is-invalid': isInvalid }"
                  @blur="validateMinMaxInput"
                  @input="handleMinInput"
                />
              </div>
              <div class="pr-2">
                <input
                  id="maxInput"
                  type="text"
                  v-model="form.maxValue"
                  placeholder="Max"
                  @keyup="validateMinMaxInput"
                  :class="{ 'is-invalid': isInvalid }"
                  @blur="validateMinMaxInput"
                  @input="handleMaxInput"
                />
              </div>
            </div>
            <p v-if="isInvalid" class="formulate-input-error">Please enter a number between 0 to 10 Million.</p>
            <p v-if="isGreater" class="formulate-input-error">Min value should not be greater than or equal max value.</p>
            <p v-if="overlapError" class="formulate-input-error">The new range cannot overlap with existing ranges.</p>
            <p v-if="nullError" class="formulate-input-error"> Field Should not be Blank</p>
          </div>
        </div>
        <!-- Submit -->
        <div class="flex mt-7 w-1/12 items-center">
          <FormulateInput
            class="px-3"
            type="submit"
            text="Submit"
            :disabled="getDisableState"
          >
          <span class="px-2">{{mode !== 'edit' ? 'Add' : 'Update'}}</span>
            <span class="px-1" v-if="createStatusLoading">
              <svg
                aria-hidden="true"
                class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </span>
          </FormulateInput>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import RiskColor from "./risk-color.vue";
// import { createRiskLabel } from "../service";
// import vue from "vue";
export default {
  name: "createRisk",
  components: {
    "risk-color": RiskColor,
  },
  props: {
    colorOptions: {
      type: Array,
      default: () => [],
    },
    
    mode: {
      type: String,
      default: "create",
    },
    status: {
      type: Object,
      default: () => {},
    },
    riskAssessmentId: {
        type: String,
        default: ''
    },
    riskEntityLabelList:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      form: {
        riskLabelEntity: "",
        RiskColor: { id: "", colour: "", disabled: false },
        riskDescription: "",
        maxValue: null,
        minValue: null,
      },
      isActive: false,
      createStatusLoading: false,
      hasNameError: false,
      hasDescriptionError: false,
      isInvalid: false,
      isGreater: false,
      nullError: false,
      overlapError: false,
    };
  },
  computed: {
    getDisableState() {
      return (
        !this.form.riskLabelEntity ||
        !this.form.RiskColor?.colour || !this.form.minValue || !this.form.maxValue ||
        this.nullError || this.overlapError || !this.form.minValue || !this.form.maxValue || 
        this.hasNameError || this.isInvalid || this.isGreater ||
        (this.form.riskDescription ? this.hasDescriptionError : false) ||
        this.createStatusLoading
      );
    },
    submitButtonClass() {
      return {
        input: "bg-red-600",
      };
    },
    colorList() {
      let updatedColorList;
      const categoryList = this.$store?.getters?.getCategoryList || [];
      const colorList = this.$store?.getters?.getColorList || [];
      const updatedColorListGetter = this.$store?.getters?.getUpdatedColorList;

      if (categoryList.length) {
        const usedColors = categoryList
          .filter(el => el.label_type === "Entity")
          .map(el => el.color);

        updatedColorList = colorList.map(el => {
          if (usedColors.includes(el.colour)) {
            return { ...el, disabled: true };
          }
          return el;
        });
      }
      return updatedColorList || updatedColorListGetter;
    },
  },
  async mounted() {
      this.$store.dispatch("fetchRiskColors");
      this.showError = false;
      if (this.status && this.mode === "edit") {
        let formData = {
          riskLabelEntity: this.status.name,
          riskDescription: this.status.description,
          RiskColor: {
            id: this.status.colour_id,
            colour: this.status.colour,
            disabled: true,
          },
          minValue: this.status.min_label_score,
          maxValue: this.status.max_label_score
        };
        this.form = formData;
      }
    },
    watch: {
      status(newVal) {
        if (this.status && this.mode === "edit") {
          let formData = {
            riskLabelEntity: newVal.name,
            riskDescription: newVal.description,
            RiskColor: {
              id: newVal.colour_id,
              colour: newVal.colour,
              disabled: true,
            },
            minValue: newVal.min_label_score,
            maxValue: newVal.max_label_score
          };
          this.form = formData;
        }
      },
    },
  methods: {  
    validateMinMaxInput() {
      const minValue = this.form.minValue;
      const maxValue = this.form.maxValue;

      this.nullError = false;
      this.isInvalid = false;
      this.isGreater = false;
      this.overlapError = false;

      if (minValue && maxValue) {
        const regex = /^(0|[1-9][0-9]{0,6}|10000000)$/;
        this.isInvalid = !regex.test(minValue) || !regex.test(maxValue);
        this.isGreater = parseInt(minValue) >= parseInt(maxValue);

        const isInvalidRange = this.riskEntityLabelList.some(range => {
          return (
            (minValue >= range.min_label_score && minValue <= range.max_label_score) ||
            (maxValue >= range.min_label_score && maxValue <= range.max_label_score) ||
            (minValue < range.min_label_score && maxValue > range.max_label_score)
          );
        });
        this.overlapError = isInvalidRange;
        if (minValue === '' || maxValue === '') {
          this.nullError = true;
        }
      } else if (minValue === '' || maxValue === '') {
        this.nullError = true;
      } else if (minValue === 0) {
        this.nullError = false;
      } 
      if(this.mode == "edit"){
        let filteredData = this.riskEntityLabelList.filter(item => item.min_label_score != this.status.min_label_score && item.max_label_score != this.status.max_label_score);
        const isInvalidRange = filteredData.some(range => {
        return (
          (minValue >= range.min_label_score && minValue <= range.max_label_score) ||
          (maxValue >= range.min_label_score && maxValue <= range.max_label_score) ||
          (minValue < range.min_label_score && maxValue > range.max_label_score)
        );
      });
      this.overlapError = isInvalidRange;
      } 
    },
    handleCancel() {
      (this.form.riskLabelEntity = ""),
        (this.form.RiskColor = { id: "", colour: "", disabled: false }),
        (this.form.riskDescription = ""),
        this.$emit("close");
    },
    async handleSubmit() {        
      this.createStatusLoading = true;
      let payload = {
        name: this.form.riskLabelEntity?.toString().trim(),
        description: this.form.riskDescription?.toString().trim(),
        color: this.form.RiskColor?.colour,
        label_type: "Entity",
        min_label_score: this.form.minValue,
        max_label_score: this.form.maxValue
      };
        this.handleCancel();
        this.$emit('submitForm', payload);
      // }
      this.createStatusLoading = false;
  },
    handleSelectColor(color) {
      this.form.RiskColor = color;
      if (this.mode === "edit") {
        this.$store.commit("SET_RISK_COLOR", color);
      }
    },
    handleNameInput(value) {
      if (this.mode === "edit") {
        this.$store.commit("SET_RISK_NAME", value);
      }
    },
    handleDescriptionInput(value) {
      if (this.mode === "edit") {
        this.$store.commit("SET_RISK_DESCRIPTION", value);
      }
    },
    handleIsActive(evt) {
      if (this.mode === "edit") {
        this.$store.commit("SET_RISK_ACTIVE", evt?.target?.checked);
      }
    },
    validationName(evt) {
      this.hasNameError = evt.hasErrors;
      this.$store.commit("SET_RISK_NAME_VALIDATION_ERROR", this.hasNameError);
    },
    validationDescription(evt) {
      this.hasDescriptionError = evt.hasErrors;
      this.$store.commit(
        "SET_RISK_DESCRIPTION_VALIDATION_ERROR",
        this.hasDescriptionError
      );
    },
    handleMaxInput(value) {
      if (this.mode === "edit") {
        this.$store.commit("SET_RISK_MAX_VALUE", value);
      }
    },
    handleMinInput(value) {
      if (this.mode === "edit") {
        this.$store.commit("SET_RISK_MIN_VALUE", value);
      }
    },
    
    validateUserValue(){
      console.log("call min and max value ")
    }
  },
};
</script>

<style>
.formulate-input[data-classification="button"] button {
  background-color: var(--theme-color-primary-300);
  border: 0;
  border-radius: 10px;
}
/* .formulate-input[data-classification="button"] button:hover {
  background-color: #137aef;
} */
.formulate-input[data-classification="button"] button:active {
  /* background-color: #0D69D5 !important; */
  border: 0;
}
.formulate-input[data-classification="button"] button[disabled]:hover {
  border: 0;
  border-radius: 10px;
}

.formulate-input[data-classification="text"] input[disabled] {
  pointer-events: none;
  opacity: 0.5;
  /* Optional: Add additional styles to convey disabled state */
  background-color: #f2f2f2;
  color: #999;
  cursor: not-allowed;
}

.input-error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-size: 14px;
}
</style>
